let headerNav = [
	{
		name: "大屏配置",
		path: "/companyInfo",
	},
	{
		name: "设备列表",
		path: "/siteList",
	},
	// {
	// 	path: '/customerEdit',
	// 	name: '设备归还',
	// },
	// {
	// 	name: '账号管理',
	// 	path: '/permision'
	// },
	// {
	// 	name: '地图管理',
	// 	path: '/map'
	// },
	// {
	//   name: "园区招商",
	//   path: "/gardenSwiper",
	// },
	// {
	//   name: "政策服务",
	//   path: "/policySwiper",
	// },
	// {
	//   name: "产业服务",
	//   path: "/serveSwiper",
	// },
	// {
	//   name: "服务工具",
	//   path: "/serveAppClass",
	// },
	// {
	//   name: "视频中心",
	//   path: "/video",
	// },
	// {
	//   name: "员工管理",
	//   path: "/jueseList",
	// },
	// {
	//   name: "系统设置",
	//   path: "/setting?type=0",
	// },
];

let sidebarList = [
	[{
		name: "大屏配置",
		path: "/companyInfo",
		parent: '公司管理'
	},
	],
	[
	],
	[{
		name: "员工列表",
		path: "/yuangongList",
		parent: '员工管理'
	}, {
		name: "角色列表",
		path: "/jueseList",
		parent: '员工管理'
	}, {
		name: "政府账号",
		path: "/governmentList",
		parent: '员工管理'
	}],
	[
		{
			name: "项目列表",
			path: "/mapList",
			parent: '地图管理'
		}
	]
];
let sidebarList2 = [
	[{
		name: "大屏配置",
		path: "/companyInfo",
		parent: '公司管理'
	},
	{
		name: "公司简介",
		path: "/companyBrief",
		parent: '公司管理'
	},
	{
		name: "相关资质",
		path: "/qualificationList",
		parent: '公司管理'
	},
	{
		name: "操作视频",
		path: "/videoList",
		parent: '公司管理'
	},
	],
	[{
		name: "设备列表",
		path: "/deviceList",
		parent: '设备管理'
	},
	{
		name: "事件列表",
		path: "/eventList",
		parent: '设备管理'
	},
	{
		name: "客户管理",
		path: "/customerList",
		parent: '设备管理'
	},
	{
		name: "租赁客户",
		path: "/leaseCustomerList",
		parent: '设备管理'
	},
	{
		name: "产权客户",
		path: "/propertyCustomerList",
		parent: '设备管理'
	},
	],
];
export {
	headerNav,
	sidebarList,
	sidebarList2
};
