import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export const routes = [
	{
		path: '/companyInfo',
		component: () => import('@/view/company/companyInfo'),
		name: 'CompanyInfo',
		meta: {
			title: '大屏配置',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/deviceList',
		component: () => import('@/view/device/deviceList'),
		name: 'DeviceList',
		meta: {
			title: '设备列表',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/deviceEdit',
		component: () => import('@/view/device/deviceEdit'),
		name: 'DeviceList',
		meta: {
			title: '设备编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/siteList',
		component: () => import('@/view/device/siteList'),
		name: 'SiteList',
		meta: {
			title: '设备列表',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/personEdit',
		component: () => import('@/view/device/personEdit'),
		name: 'personEdit',
		meta: {
			title: '人员编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
];

export const adminRoutes = [
	// {
	// 	path: '/jueseList',
	// 	component: () => import('@/view/permision/jueseList'),
	// 	name: 'jueseList',
	// 	meta: {
	// 		title: '角色列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// },
	// {
	// 	path: '/yuangongList',
	// 	component: () => import('@/view/permision/yuangongList'),
	// 	name: 'YuangongList',
	// 	meta: {
	// 		title: '员工列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// },
	// {
	// 	path: '/governmentList',
	// 	component: () => import('@/view/permision/governmentList'),
	// 	name: 'GovernmentList',
	// 	meta: {
	// 		title: '政府账号',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// },
	// {
	// 	path: '/governmentEdit',
	// 	component: () => import('@/view/permision/governmentEdit'),
	// 	name: 'GovernmentEdit',
	// 	meta: {
	// 		title: '政府账号',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// },
];
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '防坠落吊篮智能平台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
};
const threeRoutes = [];
export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '防坠落吊篮智能平台-后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	// {
	// 	path: '/connectList',
	// 	component: () => import('@/view/hide/connectDevice'),
	// 	name: 'ConnectDevice',
	// 	meta: {
	// 		title: '联网设备'
	// 	}
	// },
	{
		path: '/companyInfo',
		component: () => import('@/view/company/companyInfo'),
		name: 'CompanyInfo',
		meta: {
			title: '大屏配置',
		}
	},
	{
		path: '/deviceList',
		component: () => import('@/view/device/deviceList'),
		name: 'DeviceList',
		meta: {
			title: '设备列表',
		}
	},
	{
		path: '/deviceEdit',
		component: () => import('@/view/device/deviceEdit'),
		name: 'DeviceList',
		meta: {
			title: '设备编辑',
			noCache: true,
		}
	},
	{
		path: '/siteList',
		component: () => import('@/view/device/siteList'),
		name: 'SiteList',
		meta: {
			title: '设备列表',
			parent: '设备列表'

		}
	},
	{
		path: '/customerEdit',
		component: () => import('@/view/device/customerEdit'),
		name: 'CustomerEdit',
		meta: {
			title: '客户编辑',
			noCache: true,
			permission: '',
			parent: '设备列表'
		}
	},
	{
		path: '/personEdit',
		component: () => import('@/view/device/personEdit'),
		name: 'personEdit',
		meta: {
			title: '人员编辑',
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	},

	// {
	// 	path: '/aboutInfo',
	// 	component: () => import('@/view/aboutInfo'),
	// 	name: 'AboutInfo',
	// 	meta: {
	// 		title: '关于我们'
	// 	}
	// },
	// {
	// 	path: '/webview',
	// 	component: () => import('@/view/webview'),
	// 	name: 'Webview',
	// 	meta: {
	// 		title: '隐私服务'
	// 	}
	// },
	// {
	// 	path: '/policyInfo',
	// 	component: () => import('@/view/policyInfo'),
	// 	name: 'PolicyInfo',
	// 	meta: {
	// 		title: '政策详情'
	// 	}
	// }
];
const createRouter = () =>
	new Router({
		mode: 'hash', // require service support
		base: '/',
		scrollBehavior (to, from, savedPosition) {
			console.log('路由滚动', savedPosition);
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	});

const router = createRouter();

export function resetRouter () {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}
export default router;
