import Vue from 'vue';
import App from './App.vue';

import router from './router/router';
import store from './store/store.js';

import Ant from 'ant-design-vue';
Vue.use(Ant);
import 'ant-design-vue/dist/antd.less';
import zhCN from 'ant-design-vue/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { domain, imglink } from './siteInfo';
import './permission';
import { fetch, uploadFile } from './utils/fetchs.js';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import fabric from 'fabric'
import VueQuillEditor from 'vue-quill-editor';

// require styles
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);
Vue.use(Viewer)
Vue.use(VueQuillEditor);
Vue.prototype.$domain = domain;
Vue.prototype.$imglink = imglink;
Vue.prototype.$post = fetch;
Vue.prototype.$uploadFile = uploadFile;
Vue.config.productionTip = false;
Vue.use(fabric)

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
